import React, { useEffect, useState } from 'react'
import UserMenu from './UserMenu'
import { useNavigate } from 'react-router-dom'
import InlinePreloader from '../elements/InlinePreloader'
import {useDispatch, useSelector} from 'react-redux'
import { pullData, pushData } from '../../store/actions/syncActions'
import { authHeader, userDetails, baseUrl } from '../../utils'
import GoBackLink from '../elements/GoBackLink'
import axios from 'axios'
import Logo from '../../assets/img/logo.png'

const UserHeader = ({pageTitle, activeSession, businessDetails, hideBackButton}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const syncObject = useSelector(state => state.syncData)

  const invalidateSession = async () => {
    try {
      const headers = authHeader()
      await axios.delete(`${baseUrl}/auth/sessions`, {headers})
      localStorage.removeItem("user")
      navigate('/')
    } catch (error) {
        console.error('Error invalidating session:', error);
    }
  }
  // const [synchronizing, setSynchronizing] = useState(false);

  useEffect(() => {
  }, []);
  return (
    <div className='flex items-center justify-between w-full'>
      <div className='flex items-center'>
        {/* {!hideBackButton && <GoBackLink />} */}
        <div className='h-[30px] w-[30px] text-center mt-3'>
            {/* <img src={Logo} alt='' />  */}
        </div>
        <h3 className='hidden lg:block ml-4 text-xl font-medium text-gray-600'>{pageTitle}</h3>
      </div>
      <div className='flex flex-row-reverse items-center gap-x-4 w-full lg:w-6/12'>
        <UserMenu userDetails={userDetails()} logOut={()=>{invalidateSession()}} />
        <p className='hidden lg:block uppercase tracking-[0.2em] text-md text-nadabake-purple'>excellers</p>

          {(syncObject.pullingData || syncObject.pushingData) && <div className='flex items-center gap-x-6 justify-center text-sm text-gray-300'>
              <InlinePreloader />
              <p className='hidden lg:block'>Synchronizing...</p>
          </div>}

      </div>
    </div>
  )
}

export default UserHeader
import React, { useEffect, useState } from 'react'
import FormButton from '../components/elements/form/FormButton';
import TextField from "../components/elements/form/TextField";
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/img/logo.png'
import { nanoid } from 'nanoid'
import PasswordField from '../components/elements/form/PasswordField';
import axios from 'axios';
import { baseUrl, storeDetails } from '../utils'
import { ERROR } from '../store/types';
import { useDispatch } from 'react-redux';

const Login = () => {
  const navigate = useNavigate()
  const [authPayload, setAuthPayload] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    // const fetchDocuments = async () => {
    //   try {
    //     setLoading(true);
    //     const result = await db.allDocs({ include_docs: true });
    //     console.log(result)
    //     const sessions = result.rows.filter(document => document.doc.document === 'session');
    //     await invalidateSessions(sessions);
        
    //     setLoading(false);
    //   } catch (error) {
    //     console.error('Error fetching documents:', error);
    //   }
    // };
  
    // fetchDocuments();
  }, []);
  
  // const invalidateSessions = async (sessions) => {
  //   try {
  //       // const invalidSessions = sessions.filter(session => session.doc.active && session.doc.expiry < new Date());
  //       await Promise.all(sessions.map(async (session) => {
  //           const activeSession = await db.get(session.id);
  //           activeSession.active = false;
  //           await db.put(activeSession);
  //       }));
  //   } catch (error) {
  //     console.log('Error invalidating sessions: ', error);
  //   }
  // };

  const validateForm = () => {
    let errors = {}

    if(!authPayload.username || authPayload.username === '') {
        errors.username = true
    }

    if(!authPayload.password || authPayload.password === '') {
        errors.password = true
    }
        
    setValidationErrors(errors)
    return errors
  }

  const [error, setError] = useState(null);

  const sessionExpiry = () => {
    const currentDateObj = new Date();
    const numberOfMlSeconds = currentDateObj.getTime();
    const addMlSeconds = 60 * 60 * 1000;
    const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

    return newDateObj
  }

  const [processing, setProcessing] = useState(false);

  const createSession = async () => {
    if (Object.values(validateForm()).includes(true)) {
      return
    }

    try {
      const payload = {
        username: authPayload.username,
        password: authPayload.password
      }

      setProcessing(true)

      const response = await axios.post(`${baseUrl}/auth/sessions`, payload)
      const authToken = response.data.data.accessToken
      getUserDetails(authToken)
    } catch (error) {
      setProcessing(false)
      console.error('Error creating session:', error);
      dispatch({
        type: ERROR,
        error
      })
    }
  }

  const getUserDetails = async (authToken) => {      
    try {
        const headers = {headers: { Authorization: 'Bearer ' + authToken }}

        const response = await axios.get(`${baseUrl}/user/profile`, headers)
        const user = response.data.data

        localStorage.setItem("user", JSON.stringify({...user, ...{authToken}}));
        delete user.store.__v

        localStorage.setItem("business", JSON.stringify({...user.store, ...{
            storeId: response.data.data.store._id,
            _id: `store_${nanoid(16)}`,
            document: 'business', 
            dateCreated: new Date()
        }}))
        // console.log('Business created:', response);
        if(!user.permissions.includes('can_manage_reports')) {
          setError('Sorry, your account does not have the privileges to access this platform')
          setProcessing(false)
          return
        }

        if(!user.passwordChanged) {
          navigate('/user/change-password')
        } else{
          navigate('/user')
        }

        setError('')
    } catch (error) {
        console.error('Error fetching and setting user details:', error);
        // setCreating(false)
        setError(error.response.data.message)
    }
}

  // const clearDatabase = async () => {
  //   // const db = new PouchDB('nadabake', { adapter: 'idb' }); // Use the 'idb' adapter

  //   try {
  //       localStorage.clear(); // Clear local storage
  //       await db.destroy();
  //       console.log('Database cleared successfully.');
  //   } catch (error) {
  //       console.error('Error clearing the database:', error);
  //   }
  // };

  return (
    <div className='w-full min-h-screen flex items-center justify-center bg-blue-100 bg-opacity-50'>
      <div className='w-full md:w-8/12 lg:w-1/2 xl:w-1/3 px-5 lg:px-12'>
        {/* <img src={Logo} alt="" className='w-16 ml-auto mr-auto mb-8' /> */}
        <div className='bg-white rounded-md p-8 lg:p-12 shadow-xl shadow-blue-600/10'>
          <div className='h-[45px] w-[45px] text-center mb-5'>
            {/* <img src={Logo} alt='' />  */}
          </div>
          {/* <button onClick={()=>{clearDatabase()}}>clear db</button> */}
          <div className='w-10/12 text-left'>
            <h3 className='text-2xl mb-4'>Welcome back!</h3>
            <p className='text-gray-400'>Please provide your registered email address and password to sign in to your account</p>
          </div>

          {error && error !== '' &&<p className='text-red-600 text-sm my-3'>{error}</p>}

          <div className='my-8 w-full'>
            <TextField
              inputLabel="Your username" 
              fieldId="username" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors && validationErrors.username} 
              returnFieldValue={(value)=>{setAuthPayload({...authPayload, ...{username: value}})}}
            />
          </div>

          <div className='my-8 w-full'>
            <PasswordField
              inputLabel="Password" 
              fieldId="password" 
              inputType="password" 
              preloadValue={''}
              hasError={validationErrors && validationErrors.password} 
              returnFieldValue={(value)=>{setAuthPayload({...authPayload, ...{password: value}})}}
            />
          </div>

          <div className='w-full text-left'>
            <p className='text-gray-400 text-sm'>Forgot your password? <Link className='text-blue-500 font-medium' to='password-reset'>Click here</Link> to get a new one</p>
          </div>
          
          <div className='my-8 w-full'>
            <FormButton buttonLabel="Login to your account" buttonAction={()=>{createSession()}} processing={processing} />
          </div>

          {/* <div className='w-full text-left'>
            <p className='text-gray-400 text-sm'>Don't have an active store? You can create a store now with a new store manager. <Link className='text-blue-500 font-medium' to='setup-store'>Set up a new store.</Link></p>
          </div> */}

        </div>
      </div>
    </div>
  )
}

export default Login